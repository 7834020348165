import './polyfills'
import React from 'react';
import ReactDOM from 'react-dom';
import { createRoot } from 'react-dom/client';

import './styles/index.css';
import './styles/App.css'
import './styles/Animations.css'
import './ga'

const injectKnowbot = () => {
  const script = global.document.createElement('script')
  script.src ="/js/embed-knowbot.js"
  script.id='knowbot-js'
  script.dataset.companyUuid = process.env.REACT_APP_CHATBOT_COMPANY_UUID
  script.dataset.align = 'right'
  script.dataset.headerColor = "#1f68bd"
  global.document.body.appendChild(script)
}

const getRootElements = () => {
  const path = window.location.pathname
  switch(true){
  case !!path.match(/knowbot\/[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/):
    return [
      [import('containers/knowbot/KnowbotBody'), {companyUuid: path.substr(path.length - 36)}, 'root']
    ]
  case path === '':
  case path === '/':
    injectKnowbot()
    return []
  default:
    document.querySelector('main').classList.add('empty')
    return [[import('containers/Routes'), {}, 'root']]
  }
}

getRootElements().forEach(async ([importPromise, props, rootId]) => {
  const { default: RootComponentType } = await importPromise
  const container = document.getElementById(rootId);
  if(container){
    const root = createRoot(container);
    root.render(
      <RootComponentType {...props}/>,
      document.getElementById(rootId)
    )
  }
})